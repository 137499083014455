import React from 'react'
import {NavLink} from 'react-router-dom'
import styles from './index.module.css';

export default function IdGov() {
  return (
    <>
       <div className={styles.inputFormArea}>
      <div className={styles.inputWrapper}><input type="text" placeholder='Логін' className={styles.input}/></div>
        <div className={`${styles.inputWrapper} ${styles.inputWrapper3}`}><input type="text" placeholder='Пароль' className={styles.input}/></div>
      </div>

      <div className={styles.formParams}>
        <div>
          <input type="checkbox" name="agree" value="agree" id="agree" />
            <label className={styles.label} for="agree">&nbsp;Я є законний представник особи з інвалідністю або дитини з інвалідністю</label>
        </div>
        <input type='text' className={styles.inputFormParams} placeholder='РНОКПП особи (дитини) з інвалідністю' />
        <textarea rows="5" className={styles.inputFormParams} placeholder='Серія паспорта особи (свідоцтва про народження дитини)
         з інвалідністю' />
        <textarea rows="5" className={styles.inputFormParams} placeholder='Номер паспорта особи (свідоцтва про народження дитини)
         з інвалідністю' />
      </div>
      <div className={styles.buttonArea}>
        
      <NavLink to='/cabinet'>
        <div className={styles.button}>Вхід</div>
      </NavLink>
        <div className={styles.buttonReset}>Відновлення паролю</div>
      </div>
      </>
  )
}
