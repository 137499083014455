import React from 'react'
import Select from '../../Select/Select';
import styles from './index.module.css';

const options1 = [
  'Menu Item',
  'Menu Item',
  'Menu Item',
]

export default function Services() {
  return (
    <div className={styles.container}>
      <h3 className={styles.title} >Центр обліку бездомних громадян</h3>
      <p><strong>Центр обліку бездомних громадян</strong> — заклад соціального захисту для людей, які не мають місця для проживання, перебування і реєстрації. Центр веде облік бездомних громадян, виявляє їх, надає консультації та підтримку. При деяких центрах можуть створюватися центри нічного перебування бездомних.</p>
      <br />
      <p>Користуючись фільтрами області, району та назви, знаходьте необхідну установу у своєму регіоні.</p>
      <br />
      <div className={styles.filters}>
          <div className={styles.filter}><Select options={options1} selectName={'Область'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Район'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Назва'}/></div>
      </div>
      <div className={styles.results}>
    <div className={styles.result}>1) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>2) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>3) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>4) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>5) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>6) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>7) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    </div>
    </div>
  )
}