import React from 'react'
import styles from './index.module.css';

export default function DefaultServices() {
  return (
    <div className={styles.container}>
        <p>
        На даній сторінці можна ознайомитися з переліком суб&#39;єктів господарювання, які
виготовляють, постачають і ремонтують засоби реабілітації; послугами реабілітаційних
закладів. Для перегляду - перейдіть на вкладку бічного меню <strong>Надавачі реабілітаційних товарів та послуг.</strong>
        </p>
      <p >
      Отримати детальнішу інформацію про надавачів інших послуг (територіальні
відділення Фонду соціального захисту осіб з інвалідністю; санаторії; територіальні
центри соціального обслуговування; центри обліку бездомних громадян; будинки-інтернати; центри надання адміністративних послуг) знайти їх контактну інформацію
можна у вкладці бічного меню <strong>“Надавачі послуг”.</strong>
      </p>
    </div>
  )
}
