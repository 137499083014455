import React from 'react'
import styles from './index.module.css';

export default function Questions() {
  return (
    <div className={styles.container}>
      <div className={styles.question}>Чи корисна була ця інформація?</div>
      <div className={styles.buttons}> 
        <button className={styles.button}>Так</button>
        <button className={styles.button}>Ні</button>
      </div>
      <button className={`${styles.button} ${styles.sendInfo}`} >Повідомте про проблеми на цій сторінці</button>
    </div>
  )
}
