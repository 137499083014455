import React, { useEffect, useState } from 'react'
import styles from './index.module.css';
import Enter from '../../components/Registration/Enter/Enter';
import RegistrCBI from '../../components/Registration/RegistrCBI/RegistrCBI';
import RegistrDefault from '../../components/Registration/RegistrDefault/RegistrDefault';


export default function Registration({ dataReg }) {
  console.log(dataReg)
  
  const [activeArea, setActiveArea] = useState(dataReg);
  const [activeTitle, setActiveTitle] = useState('Реєстрація');
  
  useEffect(() => {
    setActiveArea(dataReg)
  }, [dataReg]);

  const shownArea = () => {
    switch(activeArea) {
      case 'enter': return <Enter />
      case 'cbi': return <RegistrCBI />
      case 'reg': return <RegistrDefault />
      default:
       <RegistrDefault />
    }
  }

  useEffect(() => {
    if (activeArea === 'reg') return setActiveTitle('Реєстрація')
    else {
      (activeArea === 'enter') ? setActiveTitle('Вхід в особистий кабінет ') : setActiveTitle('Реєстрація в ЦБІ')
    }
  }, [activeArea]);


  return (
    <div >
      <div className={styles.title}>{activeTitle}</div>
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={`${styles.tabButton} ${activeArea === 'enter' ? styles.active : ''}`} onClick={() => {setActiveArea('enter')}}>Вхід в особистий кабінет</div>
        <div className={`${styles.tabButton} ${activeArea === 'cbi' ? styles.active : ''}`} onClick={() => {setActiveArea('cbi')}}>Реєстрація в ЦБІ</div>
      </div>
      <div className={styles.rightSide}>
        {shownArea()}
      </div>
    </div>
  </div>
  )
}
