import React, { useState, createContext, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import useWindowDimensions from '../../hooks/getWindowDimensions/getWindowDimensions'
import styles from './index.module.css';

export default function SubHeader({childToParent}) {
  const [show, setSwow] = useState(false)
  const [showSubMenu, setShowSubMenu] = useState(false)
  const [toParent, setToParent] = useState('')

  childToParent(toParent)
  const { width } = useWindowDimensions();
  const isTablet = width < 768;

  if (!isTablet) return (
    <>
    <nav className={styles.menu}>
        <NavLink className={styles.menuItem} to='/'>Головна</NavLink>
        <NavLink className={`${styles.menuItemReg} ${styles.menuItem}`} to='/register' onMouseEnter={() => { setShowSubMenu(true) }} onMouseLeave={() => { setShowSubMenu(false) }} onClick={() => {setToParent('reg')}}
        >
            Реєстрація
            {showSubMenu ? 
      <div onMouseEnter={() => {setShowSubMenu(true)}} onMouseLeave={() => {setShowSubMenu(false)}} className={styles.subMenu}>
              <NavLink className={styles.subMenuItem} to='/register' onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setToParent('enter')
              }}>Вхід в особистий кабінет</NavLink>
      <NavLink className={styles.subMenuItem} to='/register' onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setToParent('cbi')
              }}>Реєстрація в ЦБІ</NavLink>
        </div> 
    : <></> }
          </NavLink>
        <NavLink className={styles.menuItem} to='/services'>Надавачі послуг</NavLink>
        <NavLink className={styles.menuItem} to='/contact'>Контакти</NavLink>
      </nav>
      
      </>
  )
  return (
    <nav className={styles.menu}>
      <div className={`${styles.menuItemBig} ${styles.menuItem}`} onClick={() => setSwow(!show)}>Меню</div>
      <ul className={`${styles.dropList} ${show ? styles.show : ''}`}>
        <NavLink className={styles.listItem} to='/' onClick={() => setSwow(!show)}>Головна</NavLink>
        <NavLink className={styles.listItem} to='/register' onClick={() => setSwow(!show)}>Реєстрація</NavLink>
        <NavLink className={styles.listItem} to='/services' onClick={() => setSwow(!show)}>Надавачі послуг</NavLink>
        <NavLink className={styles.listItem} to='/contact' onClick={() => setSwow(!show)}>Контакти</NavLink>
      </ul>
    </nav>
  )
}