import React from 'react'
import styles from './index.module.css';
import laptop from '../../img/main.png'

export default function Main() {
  return (
    <div className={styles.container} >
      <div className={styles.wrapper}>
      <div className={styles.leftSide}>
      <div className={styles.discription}>
      <p>
        Електронний кабінет особи з інвалідністю дозволяє швидко та у зручний:
      </p>
      <p className={styles.discriptionList}>
          <strong>1.&emsp;Подати:</strong><br />
          -&emsp;заяву про забезпечення засобом реабілітації (виплату компенсації);<br />
          -&emsp;заяву про заміну засобу реабілітації;<br />
          -&emsp;заявку про проведення ремонту засобу реабілітації;<br />
          -&emsp;оновити подані раніше документи щодо забезпечення засобом 
              реабілітації.<br />
              <br />
          <strong>2.&emsp;Ознайомитися з переліком послуг та їх надавачів</strong><br />
          Для роботи з електронним кабінетом особи з інвалідністю достатньо мати
           смартфон чи комп’ютер з виходом в інтернет. <a href='/contact'><strong>Документи подаються 
            онлайн.</strong></a><br />
             Інформація про етапи реєстрації в електронному кабінеті особи з 
інвалідністю представлена у вкладці <a href='/register'><strong>Реєстрація.</strong></a>

      </p>
      У вкладці <a href='/services'><strong>Надавачі послуг</strong></a> можна ознайомитися із переліком:<br />
          -&emsp;суб’єктів господарювання, які виготовляють, постачають і ремонтують засоби
реабілітації;<br />
          -&emsp;реабілітаційних закладів;<br />
          -&emsp;заявку про проведення ремонту засобу реабілітації;<br />
            -&emsp;надавачів соціальних послуг;<br />
            -&emsp;територіальних відділень Фонду соціального захисту осіб з інвалідністю;<br />
            -&emsp;центрів надання адміністративних послуг<br />
            <br />
          </div>
     </div>
     <div className={styles.rightSide}>
      <img src={laptop} alt='leptop'></img>
     </div>
      </div>
      <p>Інформацію про територіальні відділення Фонду соціального захисту осіб з
інвалідністю, куди можна звернутися за допомогою під час реєстрації та номер гарячої
телефонної лінії Державного підприємства &quot;Інформаційно-обчислювальний центр
Мінсоцполітики, можна знайти в розділі <a href='/contact'><strong>Контакти.</strong></a>
      </p>
    </div>
  )
}
