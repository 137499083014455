import React from 'react'
import useWindowDimensions from '../../../hooks/getWindowDimensions/getWindowDimensions'
import styles from './index.module.css';
import Select from '../../Select/Select';

const options1 = [
  'Menu Item',
  'Menu Item',
  'Menu Item',
]

export default function Technic() {
  const { width } = useWindowDimensions();
  const isTablet = width < 1300;
  return (
    <div>
      <h3 className={styles.title}>Заяви про потребу у забезпеченні в ДЗР</h3>
      <div className={styles.discription}>
      <p className={styles.discription}>У цьому розділі ви можете ознайомитись зі списком поданих Вами заяв для отримання ДЗР. </p>
      
      Також тут ви можете замовити необхідні ДЗР. <br/>
      Для того, щоб подати нову заяву, заповніть поля спеціальної форми нижче<br/>
      <strong>Прикріпіть скан-копії або фото документів (за потреби)</strong>, після чого натисніть кнопку <strong>«Подати заяву»</strong><br/>
      </div>
      {/*<div><strong><u>Подати заяву</u></strong></div>
      <div className={styles.filters}>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
      </div>
      <div className={styles.results}>
      {!isTablet ? 
      <div className={styles.results}>
      <div className={styles.result}>
          <div>1. 16.02.2024</div>
          <div>16.02.2024</div>
          <div>Група Виробу</div>
          <div>Вироб</div>
          <div>Статус</div>
        </div>
    </div> 
    :
    <div><p><strong>Вивід результатів у розробці....</strong></p></div>
    }
        
  </div>*/}
      <div className={styles.blankWrapper}>
        <div className={styles.blankArea}>
          <div className={styles.blankAreaLeft}>
            <div className={styles.imputArea}>
            <input type="text" placeholder='Область*' className={styles.input}/>
            <input type="text" placeholder='Управ. соц. захисту' className={styles.input}/>
            </div>
            <div className={styles.imputArea}>
            <input type="text" placeholder='Дата Нар.*' className={styles.input}/>
            <input type="text" placeholder='ПІБ*' className={styles.input}/>
            <input type="text" placeholder='Email*' className={styles.input}/>
            </div>
            <div className={styles.imputArea}>
            <input type="text" placeholder='Група Виробу' className={styles.input}/>
            <input type="text" placeholder='Виріб' className={styles.input}/>
            </div>
            <input type="text" placeholder='Текст Заяви*' className={styles.input}/>
          </div>
          <div className={styles.blankAreaRight}>
          <div className={styles.loadBlock}>
          <div className={styles.loadBlockBottom}>Скан-копії документів</div>
            <div className={styles.loadBlockTop}>
              <div className={styles.loadBlockTitle}>Файл</div>
              <div className={styles.loadBlockButton}>Додати файл</div>
            </div>
            
          </div>
          </div>
        </div>
        <div className={styles.blankButton}>Подати Заяву</div>
      </div>
    </div>
  )
}
