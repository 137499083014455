import React from 'react'
import useWindowDimensions from '../../../hooks/getWindowDimensions/getWindowDimensions'
import Select from '../../Select/Select';
import styles from './index.module.css';
import find from '../../../img/find.png'

const options1 = [
  'Menu Item',
  'Menu Item',
  'Menu Item',
]

export default function MailBlock() {
  const { width } = useWindowDimensions();
  const isTablet = width < 1300;

  return (
    <div>
      <h3 className={styles.title}>Вхідні повідомлення</h3>
      <p>У цьому розділі ви можете перевірити нові вхідні повідомлення та за потреби перечитати ті, що отримали раніше.</p>
      <p>Для вашої зручності та швидкого пошуку повідомлення можна відсортувати за датою, темою та статусом — прочитані та непрочитані.</p>
      <p>Для перегляду вмісту необхідного повідомлення натисніть на нього.</p>
      <div className={styles.filters}>
        <div className={styles.filter}><Select options={options1} selectName={'Фільтр-область'}/></div>
        <div className={styles.filter}><Select options={options1} selectName={'Фільтр-область'}/></div>
        <div className={styles.filter}><Select options={options1} selectName={'Фільтр-область'}/></div>
        <div className={styles.resultIco}></div>
      </div>
      {!isTablet ? 
      <div className={styles.results}>
      <div className={styles.result}>
        <div>1. 16.02.2024</div>
        <div>Тема відправлення</div>
        <div>Статус повідомлення</div>
        <div><img className={styles.resultIco} src={find} alt='find'/></div>
      </div>
      <div className={styles.result}>
        <div>1. 16.02.2024</div>
        <div>Тема відправлення</div>
        <div>Статус повідомлення</div>
        <div><img className={styles.resultIco} src={find} alt='find'/></div>
      </div>
      <div className={styles.result}>
        <div>1. 16.02.2024</div>
        <div>Тема відправлення</div>
        <div>Статус повідомлення</div>
        <div><img className={styles.resultIco} src={find} alt='find'/></div>
      </div>
    </div> 
    :
    <div><p><strong>Вивід результатів у розробці....</strong></p></div>
    }
      
    </div>
  )
}
