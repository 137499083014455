import React from 'react'
import Select from '../../Select/Select';
import styles from './index.module.css';

const options1 = [
  'Menu Item',
  'Menu Item',
  'Menu Item',
]

export default function Services() {
  return (
    <div className={styles.container}>
      <h3 className={styles.title} >Надавачі послуг</h3>
      <p>На цій вкладці представлена інформація про надавачів соціальних послуг. Користуючись фільтрами області, району та назви, знаходьте необхідну установу у своєму регіоні.</p>
      <p><strong>Органи соціального захисту населення</strong> надають підтримку та послуги для людей з інвалідністю з метою їх соціальної інтеграції. Вони встановлюють статус та надають соціальні пільги, займаються реабілітацією та навчанням, а також захищають їхні права та інтереси.</p>
      <p><strong>Санаторії</strong> спеціалізуються на реабілітації та відновленні здоров'я через надання різноманітних лікувальних та реабілітаційних послуг, орієнтованих на різні види захворювань та станів організмунадають підтримку та послуги для людей з інвалідністю з метою їх соціальної інтеграції. Вони встановлюють статус та надають соціальні пільги, займаються реабілітацією та навчанням, а також захищають їхні права та інтереси.</p>
      <p><strong>Територіальні центри соціального обслуговування</strong> — це державні установи, що надають соціальні послуги громадянам у складних життєвих обставинах, таким як люди похилого віку, особи з інвалідністю, безробітні або ті, хто має на утриманні осіб, які потребують постійної допомоги.</p>
      <p><strong>Центр обліку бездомних громадян</strong> — заклад соціального захисту для людей, які не мають місця для проживання, перебування і реєстрації. Центр веде облік бездомних громадян, виявляє їх, надає консультації та підтримку. При деяких центрах можуть створюватися центри нічного перебування бездомних.</p>
      <p><strong>Будинок-інтернат</strong>  для людей похилого віку та людей з інвалідністю, геріатричний пансіонат, пансіонат для ветеранів війни та праці — це соціально-медична установа для денного, тимчасового або постійного проживання людей похилого віку, які потребують догляду і медичного обслуговування.</p>
      <br />
      <p>Користуючись фільтрами області, району та назви, знаходьте необхідну установу у своєму регіоні.</p>
      <br />
      <div className={styles.filters}>
          <div className={styles.filter}><Select options={options1} selectName={'Область'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Район'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Назва'}/></div>
      </div>
      <div className={styles.results}>
    <div className={styles.result}>1) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>2) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>3) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>4) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>5) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>6) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>7) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    </div>
    </div>
  )
}