import React from 'react'
import {NavLink} from 'react-router-dom'
import styles from './index.module.css';

export default function StatementCBI() {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Зчитати приватний ключ</h3>
      <p> Content toward the top of the page should talk about the most important information you want to share. It's a good place to give an overview or introduction to your company or product and should be relevant to the rest of the content on the page.</p>
      <br />
      <div className={styles.regFormArea}>
        <div className={styles.tabButtons}>
        <NavLink className={styles.tabButton} to='/regcbi'>Реєстрація на порталі ЦБІ</NavLink>
        <div className={`${styles.tabButton} ${styles.active}`}>Заява на порталі ЦБІ</div>
        </div>
        <div className={styles.inputFormArea}>
          <div className={styles.key}><input type="text" placeholder='АЦСК ключа' className={styles.input}/></div>
          <div className={styles.file}><input type="text" placeholder='Файл' className={styles.input}/></div>
          <div className={styles.hide}><input type="text" placeholder='Пароль' className={styles.input}/></div>
        </div>
        <div className={styles.buttonArea}>
          <NavLink to='/regcbi'>
            <div className={styles.button} onClick={()=> {}}>Прийняти</div>
          </NavLink>
        </div>
      </div>
    </div>
  )
}
