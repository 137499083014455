import React from 'react'
import styles from './index.module.css';
import Select from '../../components/Select/Select';
import contact from '../../img/contact.png'

const options1 = [
  'Menu Item',
  'Menu Item',
  'Menu Item',
]

export default function Contact() {
  return (
    <div >
      <div className={styles.wrapper}>
      <h2 className={styles.title}>Контакти</h2>
      </div>
      <div className={styles.containerSection }>
        <div className={styles.container}>
        <div className={styles.leftSide}>
          <p className={styles.discription}>Для консультації з соціальних питань працює «гаряча лінія» <strong>1539</strong>.</p>
            <p className={styles.discription}>З технічних питань роботи Електронного кабінету кол-центр Державного підприємства «Інформаційно-обчислювальний центр Мінсоцполітики»:<br />
            callcenter@ioc.gov.ua<br/>
            (044) 494-19-10</p>
            

          <p className={styles.discription}>Ви також можете звернутися до територіальних відділень Фонду соціального захисту
осіб з інвалідністю за місцем проживання. Їхні адреси та телефони вказані нижче. Щоб
обрати потрібну установу, скористайтеся фільтрами.</p>
          <p className={styles.discription}>Спершу оберіть у списку область, потім оберіть місто і район.</p>
        </div>
          <div className={styles.rightSide}>
          <img src={contact} alt='leptop'></img>
          </div>
        </div>
      <div className={styles.buttomSide}>
      </div>
        <form className={styles.form}>
          <Select options={options1} selectName={'Фільтр-область'}/>
          <Select options={options1} selectName={'Фільтр-область'}/>
          <Select options={options1} selectName={'Фільтр-область'}/>
        </form>
        <p className={styles.result}>
          Управління соціального захисту населення Березівської районної<br />
          адміністрації Одеської області , м. Березівка, вул. Шевченка,7<br />
          Керівник - Моряк Микола Федорович<br />
          к.т. +38 (04856) 201-35
        </p>
      </div>
  </div>
  )
}
