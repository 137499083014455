import React, { useState, useEffect } from 'react'
import styles from './index.module.css';
import scroll from '../../img/scroll.png'
import scrollHover from '../../img/scroll-hover.png'

export default function ScrollToTop() {
  const [icon, setIcon] = useState(scroll);
  const [show, setShow] = useState(false);

useEffect(() => {
  window.addEventListener("scroll", 
  () => {
    if(window.scrollY > 300) {
      return setShow(true)
    } else return setShow(false)
  } 
)});

if(show) return (
    <div className={styles.button} 
      onClick={() => {window.scrollTo({top: 0, left: 0, behavior: "smooth"})}}>
      <img src={icon} alt='scroll to top button' onMouseEnter={()=> {setIcon(scrollHover)}} onMouseLeave={()=> {setIcon(scroll)}}></img>
    </div>
  )
}

