import styles from './index.module.css';

export default function SubHeader({ isMain }) {
  if (isMain)
  return (
    <div className={styles.container}>
      <h1>Електронний кабінет особи з інвалідністю</h1>
      <h2>Соціальні послуги без черг</h2>
    </div>
  )
}
