import React, {useState} from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout/Layout';

import Main from './pages/Main/Main'
import Contact from './pages/Contact/Contact'
import Registration from './pages/Registration/Registration'
import Services from './pages/Services/Services'
import Login from './pages/Login/Login'
import Cabinet from './pages/Cabinet/Cabinet'
import RegCBI from './pages/RegCBI/RegCBI';
import StatementCBI from './pages/StatementCBI/StatementCBI';
import FirstStatementCBI from './pages/FirstStatementCBI/FirstStatementCBI';

function App() {
  const [data, setData] = useState('');

  const childToParent = (childdata) => {
    setData(childdata);
  }
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout path childToParent={childToParent}/>}>
          <Route index element={<Main />} />
          <Route path='contact' element={<Contact />} />
          <Route path='register' element={<Registration dataReg={data ? data : ''} />} />
          <Route path='services' element={<Services />} />
          <Route path='login' element={<Login />} />
          <Route path='cabinet' element={<Cabinet />} />
          <Route path='regcbi' element={<RegCBI />} />
          <Route path='statementcbi' element={<StatementCBI />} />
          <Route path='firststatementcbi' element={<FirstStatementCBI />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
