import React, { useMemo, useState } from 'react'
import styles from './index.module.css';
import DefaultServices from '../../components/Services/DefaultServices/DefaultServices';
import ServicesDRZ from '../../components/Services/ServicesDRZ/ServicesDRZ';
import Service from '../../components/Services/Service/Service';
import Protez from '../../components/Services/Protez/Protez';
import Sanatorium from '../../components/Services/Sanatorium/Sanatorium';
import Center from '../../components/Services/Center/Center';
import Houses from '../../components/Services/Houses/Houses';
import Rehabilitation from '../../components/Services/Rehabilitation/Rehabilitation';
import Teretorial from '../../components/Services/Teretorial/Teretorial';
import Organ from '../../components/Services/Organ/Organ';
import Fond from '../../components/Services/Fond/Fond';
import ZNP from '../../components/Services/ZNP/ZNP';

export default function Services() {
  const [activeArea, setActiveArea] = useState('')
  const [title, setTitle] = useState('Надавачі послуг')
	const [showSubMenuFirst, setShowSubMenuFirst] = useState(false)
	const [showSubMenuSecond, setShowSubMenuSecond] = useState(false)
  
  const shownArea = useMemo(() => {
    switch(activeArea) {
      case 'drz': setTitle('Надавачі послуг ДЗР'); return <ServicesDRZ />
      case 'services': setTitle('Надавачі послуг'); return <Service />
      case 'organ': setTitle('Надавачі послуг');  return <Organ />
      case 'fond': setTitle('Надавачі послуг'); return <Fond />
      case 'znp': setTitle('Надавачі послуг'); return <ZNP />
      case 'sanator': setTitle('Надавачі послуг');  return <Sanatorium />
      case 'protez': setTitle('Надавачі послуг');  return <Protez />
      case 'center': setTitle('Надавачі послуг');  return <Center />
			case 'house': setTitle('Надавачі послуг'); return <Houses />
			case 'rehabilitation': setTitle('Надавачі послуг'); return <Rehabilitation />
			case 'teretorial': setTitle('Надавачі послуг'); return <Teretorial />
			case '': setTitle('Надавачі послуг'); return <DefaultServices />
      default:
       <DefaultServices />
    }
  }, [activeArea])

  const clickOnMainMenuFirst = () => {
		setActiveArea('drz')
    setShowSubMenuFirst(!showSubMenuFirst)
	};
	
	const clickOnMainMenuSecond = () => {
		setActiveArea('services')
    setShowSubMenuSecond(!showSubMenuSecond)
  }

  return (
    <div >
    <div className={styles.title}>{title}</div>
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={`${styles.tabButton} ${styles.tabButtonShow} ${activeArea === 'drz' ? styles.active : ''}`} onClick={clickOnMainMenuFirst}>Надавачі реабілітаційних послуг та виробники ДЗР</div>
        <div className={`${styles.tabButton} ${activeArea === 'protez' ? styles.active : ''} ${showSubMenuFirst ? styles.tabButtonShow : null}`} onClick={() => {setActiveArea('protez')}}> 	&bull; Суб’єкти господарювання, які виготовляють, постачають і ремонтують допоміжні засоби
реабілітації</div>
        <div className={`${styles.tabButton} ${activeArea === 'rehabilitation' ? styles.active : null} ${showSubMenuFirst ? styles.tabButtonShow : null}`} onClick={() => {setActiveArea('rehabilitation')}}>	&bull; Ребіалітаційні заклади</div>
          <div className={`${styles.tabButton} ${styles.tabButtonShow} ${activeArea === 'services' ? styles.active : null}`} onClick={clickOnMainMenuSecond}>Надавачі послуг</div>
          <div className={`${styles.tabButton} ${activeArea === 'organ' ? styles.active : null} ${showSubMenuSecond ? styles.tabButtonShow : null}`} onClick={() => { setActiveArea('organ') }}> 	&bull; Органи соціального захисту населення</div>
          <div className={`${styles.tabButton} ${activeArea === 'fond' ? styles.active : null} ${showSubMenuSecond ? styles.tabButtonShow : null}`} onClick={() => {setActiveArea('fond')}}> 	&bull; Територіальні відділення Фонду соціального захисту осіб з інвалідністю</div>
        <div className={`${styles.tabButton} ${activeArea === 'sanator' ? styles.active : null} ${showSubMenuSecond ? styles.tabButtonShow : null}`} onClick={() => {setActiveArea('sanator')}}> 	&bull; Санаторії</div>
        <div className={`${styles.tabButton} ${activeArea === 'teretorial' ? styles.active : null} ${showSubMenuSecond ? styles.tabButtonShow : null}`} onClick={() => {setActiveArea('teretorial')}}> 	&bull; Тереторіальні центри соціального обслуговування</div>
        <div className={`${styles.tabButton} ${activeArea === 'center' ? styles.active : null} ${showSubMenuSecond ? styles.tabButtonShow : null}`} onClick={() => {setActiveArea('center')}}> 	&bull; Центри обліку бездомних громадян</div>
          <div className={`${styles.tabButton} ${activeArea === 'house' ? styles.active : null} ${showSubMenuSecond ? styles.tabButtonShow : null}`} onClick={() => { setActiveArea('house') }}> 	&bull; Будинки-інтернати</div>
          <div className={`${styles.tabButton} ${activeArea === 'znp' ? styles.active : null} ${showSubMenuSecond ? styles.tabButtonShow : null}`} onClick={() => {setActiveArea('znp')}}> 	&bull; Центри надання адміністративних послуг (ЦНАП)</div>
      </div>
      <div className={styles.rightSide}>
        {shownArea}
      </div>
    </div>
  </div>
  )
}