import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './index.module.css';
import play from '../../../img/play.svg';

export default function RegistrDefault() {
  return (
    <div className={styles.container}>
      <span className={styles.sub} >Зміст сторінки:</span><br/>
      <br/>
      {/*<a className={styles.anchor} href='#"how_to_reg'>Як отримати доступ до електронного кабінету особи з інвалідністю?</a>
      <a className={styles.anchor} href='#why_to_reg'>Чому реєстрація важлива</a>
  <a className={styles.anchor} href='#short'>Скорочення, які вживаються </a>*/}
      <NavLink className={styles.anchor} to='/login'>Вхід в особистий кабінет</NavLink>
      <NavLink className={styles.anchor} to='/firststatementcbi'>Реєстрація в ЦБІ</NavLink>

      <div>
        <p id="how_to_reg">
        <strong>Як отримати доступ до електронного кабінету особи з інвалідністю?</strong><br/>
          Щоб користуватися електронним кабінетом, спершу потрібно <strong>зареєструватися</strong> у спеціальній базі, яка називається Централізований банк даних з проблем інвалідності (ЦБІ). 
          Детально кожен з кроків реєстрації описаний у меню зліва, кнопка <strong>Реєстрація в ЦБІ</strong>
        </p>
        {/*<p>
        <strong>Вже зареєстровані в ЦБІ? </strong>Увійдіть в особистий кабінет, кнопка <u><strong>Вхід в ОК</strong></u> у меню зліва або натисніть на кнопку «Вхід» у верхній правій частині вікна.
        </p>
        <p className={styles.how}>Як це зробити:<br /></p>
        <p className={styles.discriptionList}>
          -&emsp;заповніть <u>реєстраційну форму</u>;<br />
          -&emsp;прикріпіть сканкопії або фото трьох документів: паспорта, індивідуального податкового номера та документа, що дає право на пільгу;<br />
          -&emsp;введіть дані КЕП<sup>&#63;</sup> (або спеціального коду, який видає орган соцзахисту)<br />
          -&emsp;дайте згоду на обробку персональних даних.<br />
        </p>*/}
      </div>
      <p id="why_to_reg">
        <strong>Чому реєстрація важлива</strong><br />
        Реєстрація в ЦБІ відкриває доступ до широкого спектра послуг та можливостей. Ви
зможете <strong>ОНЛАЙН</strong> замовити необхідні послуги чи допоміжні засоби реабілітації та
бачити результат розгляду заявки. Реєстрація в ЦБІ є безпечною, оскільки
використовуються передові технології захисту даних, щоб забезпечити
конфіденційність особистої інформації.
      </p>

      <p><strong>Вже зареєстровані в ЦБІ?</strong> Увійдіть до особистого кабінету, натисніть на кнопку «Вхід» у верхній правій частині вікна.</p>
      <p id="short">
        <strong>Скорочення, які тут вживаються</strong><br />
        ЦБІ – Централізований банк даних з проблем інвалідності.<br />
        ДЗР – допоміжні засоби реабілітації (протезно-ортопедичні вироби, спеціальні засоби<br />
        для самообслуговування та догляду тощо).<br />
        КЕП – кваліфікований електронний підпис.<br />
        РНОКПП – реєстраційний номер облікової картки платника податків.<br />
        МСЕК - медико-соціальна експертна комісія<br />
        ЛКК - лікарсько-консультативна комісія<br />
        ВЛК - військово-лікарська комісія<br />
        МДРК - мультидисциплінарна реабілітаційна команда<br />
      </p>
      <div className={styles.video} >
        <img src={play} alt='play'></img>
      </div>
      
    </div>
  )
}
