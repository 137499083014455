import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import styles from './index.module.css';

export default function RegCBI() {
  const [checked, setChecked] = useState(true)
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Зчитати приватний ключ</h3>
      <p> Content toward the top of the page should talk about the most important information you want to share. It's a good place to give an overview or introduction to your company or product and should be relevant to the rest of the content on the page.</p>
      <br />
      <div className={styles.regFormArea}>
        <div className={styles.tabButtons}>
        <div className={`${styles.tabButton} ${styles.active}`}>Реєстрація на порталі ЦБІ</div>
        <NavLink className={styles.tabButton} to='/statementcbi'>Заява на порталі ЦБІ</NavLink>
        </div>
        <div className={styles.inputFormArea}>
          <input type="text" placeholder='Логін' className={styles.input}/>
          <div className={styles.hide}><input type="text" placeholder='Пароль' className={styles.input}/></div>
          <div className={styles.hide}><input type="text" placeholder='Пітвердження Паролю' className={styles.input}/></div>
          <div className={styles.post}><input type="text" placeholder='Електронна адреса' className={styles.input}/></div>
          <div className={styles.choiseArea}>
            <div className={`${styles.choiseButton} ${checked ? styles.checked : null}`} onClick={() => (setChecked(!checked))}>Без КЕП</div>
            <div className={`${styles.choiseButton} ${!checked ? styles.checked : null}`} onClick={() => (setChecked(!checked))}>З КЕП</div>
          </div>
          <div className={styles.key}><input type="text" placeholder='Код авторизації' className={styles.input}/></div>
          <div className={styles.checkbox}>
            <input type="checkbox" name="personal-data" value="personal-data" id="personal-data" />
            <label for="personal-data"><strong>&nbsp;Згода на обробку персональних даних</strong></label>
          </div>
        </div>
        <div className={styles.buttonArea}>
          <NavLink to='/cabinet'>
            <div className={styles.button} onClick={()=> {}}>Зареєструватись</div>
          </NavLink>
          <a className={styles.buttonReset} href='/register'>Як зареєструватися?</a>
        </div>
      </div>
    </div>
  )
}