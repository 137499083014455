import React from 'react'
import {useLocation, Outlet} from 'react-router-dom'
import Header from '../Header/Header'
import Nav from '../Nav/Nav'
import Questions from '../Questions/Questions'
import Footer from '../Footer/Footer'
import styles from './index.module.css';
import ScrollToTop from '../ScrollToTop/ScrollToTop'


export default function Layout({childToParent}) {
  const localtion = useLocation();
  const isCabinet = localtion.pathname === '/cabinet';
  return (
    <>
      <ScrollToTop />
      <div className={styles.container}>
        <div className={styles.topSide}>
        < Header />
          <Nav childToParent={childToParent} />
          <main>
            <Outlet />
          </main>
        </div>
      <div className={styles.bottomSide}>
        {!isCabinet ? <Questions /> : null}
        <Footer />
      </div>
    </div>
    </>
  )
}
