import React from 'react'
import styles from './index.module.css';

export default function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        2023, Міністерство соціальної політики України <br />
        Розробник: ТОВ "Медирент" Версія: 2.16.0
      </div>
      <div className={styles.tel}>
        1539 – “Гаряча лінія” із соціальних питань<br />
        з технічних питань роботи ел.кабінетів кол-центр ДП "ІОЦ": тел.(044) 494-19-10<br />
        Відділ технічної підтримки ДП "ІОЦ": тел.(044) 494-19-26
      </div>
    </div>
  )
}
