import React from 'react'
import {NavLink} from 'react-router-dom'
import styles from './index.module.css';

export default function Enter() {
  return (
    <div className={styles.container}>
      <span className={styles.sub} >Зміст сторінки:</span>
      <a className={styles.anchor} href='#process'>Процес входу в особистий кабінет</a>
      <a className={styles.anchor} href='#kep'>Вхід за КЕП</a>
      <a className={styles.anchor} href='#gov'>Вхід за ID.GOV.UA </a>
      <br />
      <p id="process">
        <strong>Процес входу в особистий кабінет</strong><br/>
        Увійти до особистого кабінету можна двома способами:<br />
        <p className={styles.discriptionList}>
          	&bull;&emsp;за КЕП;<br />
          	&bull;&emsp;за ID.GOV.UA.<br />
        </p>
      </p>

      <p id="kep">
        <strong>Вхід за КЕП (кваліфікованим електронним підписом)</strong><br />
        Після обрання варіанту входу за КЕП відкриється вікно, де потрібно внести наступну інформацію:
        <p className={styles.discriptionList}>
          	&bull;&emsp;АЦСК ключа — потрібно обрати зі списку назву установи, що видала КЕП.<br />
          	&bull;&emsp;файл — потрібно завантажити КЕП. Для цього натиснути знак плюс в рядку, де запитують файл і обрати файл з ключем)<br />
          	&bull;&emsp;пароль (вводячи пароль у графі будуть з'являтися знаки (кружечки). Це допомагає захистити пароль від сторонніх поглядів. Якщо натиснути на зображення ока в графі, де вводиться пароль — замість символів з'являться введені дані).<br />
        </p>
        <p>Розпочати роботу з електронним кабінетом можна, якщо у вас є <strong>кваліфікований електронний підпис (КЕП).</strong></p>
      <p>Це вид електронного підпису, яким можна підписувати електронні документи.</p>
      <p>Він має таку саму юридичну силу, як і звичайний підпис людини.</p>
      <p>Електронний підпис можна отримати <storng>безкоштовно</storng>, наприклад, через портал «Дія», «Приват 24» або іншого провайдера. Повний перелік провайдерів КЕП є на&nbsp;
        <a href='czo.gov.ua' className={styles.link}>
          сайті Міністерства цифрової трансформації України
        </a>
      .</p>
      <NavLink className={styles.button} to='/regcbi'>Де отримати КЕП</NavLink>
      <p>Отримати КЕП можна і особисто, звернувшися до обраного провайдера. Для цього з собою обов'язково мати документ, що засвідчує особу.</p>
      </p>

      <p id="gov">
        <strong>Вхід за ID.GOV.UA</strong><br />
        <p>
        ID.GOV.UA – це платформа для електронної ідентифікації та автентифікації користувачів для отримання ними електронних послуг, доступу до сервісів.<br />
        Пройти е-ідентифікацію можна через електронні підписи (на файловому, хмарному чи інших захищених носіях), Дія.Підпис та BankID НБУ.
        </p>
      </p>

      <NavLink className={styles.button} to='/login'>Вхід в особистий кабінет</NavLink>
    </div>
  )
}

