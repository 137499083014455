import React, { useState } from 'react'
import styles from './index.module.css';

import masseges from '../../img/masseges.png'
import avatar from '../../img/avatar.png'
import mail from '../../img/8.png'
import teh from '../../img/7.png'
import tzr from '../../img/3.png'
import reb from '../../img/1.png'
import bus from '../../img/5.png'
import cancel from '../../img/4.png'
import repair from '../../img/6.png'
import updade from '../../img/9.png'
import Questions from '../../components/Questions/Questions';

import MailBlock from '../../components/Cabinet/MailBlock/MailBlock';
import DefaultBlock from '../../components/Cabinet/DefaultBlock/DefaultBlock';
import Technic from '../../components/Cabinet/Technic/Technic';
import Enterprise from '../../components/Cabinet/Enterprise/Enterprise';


export default function Cabinet() {

  const [activeArea, setActiveArea] = useState('')

  const shownArea = () => {
    switch(activeArea) {
      case 'mail': return <MailBlock />
      case 'teh': return <Technic />
      case 'bus': return <Enterprise />
      case '': return <DefaultBlock />
      default:
       <DefaultBlock />
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.avatar}>
          <img src={avatar} alt='avatar' className={styles.avatarIco}/>
          <div className={styles.name}>Семченко Семен<br />Семенович</div>
          <div className={styles.email}>andrewmail76@gmail.com</div>
        </div>
        <div className={styles.menu}>
          <div className={styles.menuTitle}>Меню</div>
          <div className={styles.menuTabs}>
            <div className={`${styles.menuTab} ${activeArea === 'mail' ? styles.active : ''}`} onClick={() => {setActiveArea('mail')}}>
              <img src={mail} alt='mail' className={styles.tabIco}/>
              <div className={styles.tabTitle}>Вхідні повідомлення <img src={masseges} alt='number of masseges'/></div>
            </div>
            <div className={`${styles.menuTab} ${activeArea === 'teh' ? styles.active : ''}`} onClick={() => {setActiveArea('teh')}}>
              <img src={teh} alt='teh' className={styles.tabIco}/>
              <div className={styles.tabTitle}>Заяви про потребу у забезпеченні в ДЗР</div>
            </div>
            <div className={`${styles.menuTab} ${activeArea === 'tzr' ? styles.active : ''}`} onClick={() => {setActiveArea('tzr')}}>
              <img src={tzr} alt='tzr' className={styles.tabIco}/>
              <div className={styles.tabTitle}>Отримані ДЗР</div>
            </div>
            <div className={`${styles.menuTab} ${activeArea === 'reb' ? styles.active : ''}`} onClick={() => {setActiveArea('reb')}}>
              <img src={reb} alt='reb' className={styles.tabIco}/>
              <div className={styles.tabTitle}>Заяви про направлення до реабілітаційних установ</div>
            </div>
            <div className={`${styles.menuTab} ${activeArea === 'bus' ? styles.active : ''}`} onClick={() => {setActiveArea('bus')}}>
              <img src={bus} alt='bus' className={styles.tabIco}/>
              <div className={styles.tabTitle}>Заявки про намір забезпечення ДЗР</div>
            </div>
            <div className={`${styles.menuTab} ${activeArea === 'cancel' ? styles.active : ''}`} onClick={() => {setActiveArea('cancel')}}>
              <img src={cancel} alt='cancel' className={styles.tabIco}/>
              <div className={styles.tabTitle}>Відмови в отриманні ДЗР</div>
            </div>
            <div className={`${styles.menuTab} ${activeArea === 'repair' ? styles.active : ''}`} onClick={() => {setActiveArea('repair')}}>
              <img src={repair} alt='repair' className={styles.tabIco}/>
              <div className={styles.tabTitle}>Заявки на проведення ремонту </div>
            </div>
            <div className={`${styles.menuTab} ${activeArea === 'updade' ? styles.active : ''}`} onClick={() => {setActiveArea('updade')}}>
              <img src={updade} alt='updade' className={styles.tabIco}/>
              <div className={styles.tabTitle}>Оновлення документів</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightSide}>
        <div className={styles.wrapper}>
          {shownArea()}
        </div>
        <Questions />
      </div>
    </div>
  )
}
