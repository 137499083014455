import React from 'react'
import styles from './index.module.css';

export default function FirstStatementCBI() {
  return (
    <div className={styles.container}>
      <div>
        <h3 className={styles.title}>Заяви на первинну Реєстрацію в ЦБІ</h3>
        <p> Content toward the top of the page should talk about the most important information you want to share. It's a good place to give an overview or introduction to your company or product and should be relevant to the rest of the content on the page.</p>
        <br />
      </div>
      <div className={styles.formArea}>
        <div className={styles.formAreaLeft}>
        <input type="text" placeholder='Область*' className={styles.input}/>
        <input type="text" placeholder='Управління соціального захисту*' className={styles.input}/>
        <input type="text" placeholder='Прізвище*' className={styles.input}/>
        <input type="text" placeholder='Ім’я*' className={styles.input}/>
        <input type="text" placeholder='По-батькові*' className={styles.input}/>
        <div className={styles.inputsBlock}>
          <div className={styles.inputsLeft}>
            <input type="text" placeholder='Стать*' className={styles.input}/>
            <input type="text" placeholder='Електронна адреса*' className={styles.input}/>
            <input type="text" placeholder='РНОКПП*' className={styles.input}/>
            <input type="text" placeholder='Серія паспорта' className={styles.input}/>
          </div>
          <div className={styles.inputsRight}>
            <input type="text" placeholder='Дата народження*' className={styles.input}/>
            <input type="text" placeholder='Телефон*' className={styles.input}/>
            <div className={styles.checkbox}>
            <input type="checkbox" name="cancel" value="cancel" id="cancel" />
            <label for="cancel">&nbsp;Відмова від РНОКПП</label>
          </div>
            <input type="text" placeholder='Номер паспорта' className={styles.input}/>
          </div>
        </div>
        <input type="text" placeholder='РНОКПП уповноваженої особи' className={styles.input}/>
        <input type="text" placeholder='Серія свідоцтва про народження особи з інвалідністю' className={styles.input}/>
        <input type="text" placeholder='Номер свідоцтва про народження особи з інвалідністю' className={styles.input}/>
        </div>
        <div className={styles.formAreaRight}>
          <div className={styles.documentsBlock}>
            <div className={styles.formRightTitle}>Скан-копії документів:</div>
            <br />
            <ol>
              <li>Паспорт із пропискою*</li>
              <li>РНОКПП*</li>
              <li>Документ, який надає право на пільгу*</li>
              <li>Довідка МСЕК/ЛКК/ВЛК</li>
              <li>ІПР</li>
              <li>Довідка ВПО</li>
              <li>Пенсійне посвідчення</li>
            </ol>
          </div>
          <div className={styles.loadBlock}>
            <div className={styles.loadBlockTop}>
              <div className={styles.loadBlockTitle}>Скан-копії документів</div>
              <div className={styles.loadBlockButton}>Додати файл</div>
            </div>
            <div className={styles.loadBlockBottom}>Скан-копії документів (розмір файлів не повинен перевищувати 3 Мб)</div>
          </div>
        </div>
      </div>
      <div className={styles.bottomBlock}>
        <div className={styles.checkbox}>
          <input type="checkbox" name="agree" value="agree" id="agree" />
          <label for="agree">&nbsp;Згода на надання персональних данних</label>
        </div>
        <div className={styles.loadBlockButton}>Продовжити</div>
      </div>

    </div>
  )
}
