import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './index.module.css';
import play from '../../../img/play.svg';

export default function RegistrCBI() {
  return (
    <div className={styles.container}>
      <span className={styles.sub} >Зміст сторінки:</span><br />
      <br />
      <a className={styles.anchor} href='#about'>Що таке Централізований банк даних з проблем інвалідності</a>
      <a className={styles.anchor} href='#before'>Перш ніж розпочати реєстрацію</a>
      <a className={styles.anchor} href='#reg'>Реєстрація в ЦБІ</a>
      <a className={styles.anchor} href='#how'>Як подати заяву, якщо ви – законний представник людини з інвалідністю</a>
      <br />

      <p id="about">
        <strong>Що таке Централізований банк даних з проблем інвалідності</strong><br />
        <br/>
        <p>Централізований банк даних з проблем інвалідності - система для визначення потреб
осіб з інвалідністю, дітей з інвалідністю та інших окремих категорій населення, які
відповідно до законодавства мають право на забезпечення допоміжними та іншими
засобами реабілітації, реабілітаційними послугами, санаторно-курортним лікуванням
          тощо.</p>
          <br/>
        <p>ЦБІ та Електронний кабінет особи з інвалідністю є взаємопов’язаними. Для
користування Електронним кабінетом особи з інвалідністю необхідно спочатку бути
          зареєстрованим в ЦБІ.</p>
        <br />
        <p>Якщо ви раніше <strong>не були зареєстровані</strong> в ЦБІ, вам необхідно подати заяву для внесення даних до ЦБІ.</p>
      </p>

      <p id="before">
        <u>Перш ніж розпочати реєстрацію, необхідно мати:</u>
        <p><ul className={styles.discriptionList}>
          <li>доступ до інтернету та комп’ютеру (чи іншого гаджету, що допоможе здійснити
процес реєстрації);</li>
          <li>електронну пошту та номер мобільного телефону;</li>
          <li>скановані копії або фото наступних документів:</li>
          &emsp;&emsp;&emsp;&emsp;паспорт громадянина України,<br />
          &emsp;&emsp;&emsp;&emsp;податковий номер (РНОКПП)<br />
          &emsp;&emsp;&emsp;&emsp;документ, який посвідчує право на пільгу — висновок МСЕК або ЛКК, ВЛК, МДРК<br />
          <li>цифровий електронний підпис (КЕП)</li>
        </ul></p>
        <p>
          Якщо Заяву подає законний представник – подаються документи Особи, чиї інтереси представляються. Законному представнику необхідно мати електронну копію
документу, що підтверджує його повноваження.
        </p>
        <strong>Зверніть увагу, що сканкопії документів не повинні перевищувати 3 МБ. </strong>
      </p>

      {/*<p id="preparation">
        <u>Підготовка до реєстрації в ЦБІ</u><br />
        У цьому розділі ви можете подати заяву на реєстрацію в ЦБІ. Для цього потрібно:
        <p><ol className={styles.discriptionList}>
          <li>заповнити форму реєстрації;</li>
          <li>прикріпити  скан-копії або фото необхідних документів;</li>
          <li>підписати все електронним підписом (КЕП).</li>
        </ol></p>
        <p>
          Якщо натиснути кнопку <strong>Заява на реєстрацію в ЦБІ</strong>, з'явиться форма для заповнення. У полях, де написано «оберіть», на екрані буде з'являтися список, з якого потрібно обрати вашу відповідь. У полях, де вказано «напишіть», потрібно самостійно внести необхідну інформацію.
        </p>
        <strong>Як заповнити заяву на реєстрацію в ЦБІ:</strong><br />
        <p><ul className={styles.discriptionList}>
          <li>оберіть область, де ви живете, зі списку на екрані;</li>
          <li>оберіть управління соціального захисту за місцем свого проживання зі списку на екрані;</li>
          <li>напишіть своє прізвище;</li>
          <li>напишіть своє ім’я;</li>
          <li>напишіть своє по батькові;</li>
          <li>оберіть свою стать зі списку на екрані;</li>
          <li>напишіть дату народження – ручне заповнення дати або використання «календаря»;</li>
          <li>напишіть свою електронну адресу;</li>
          <li>напишіть свій номер телефону;</li>
          <li>вкажіть свій податковий номер (РНОКПП). Якщо його немає, треба поставити відповідну позначку біля напису “Відмова від РНОКПП”</li>
          <li>вкажіть серію та номер паспорта. Серія паспорта – (2 букви кирилиці), номер паспорта – шість (6) цифр для паспортів старого зразку та дев'ять (9) цифр для ІД-картки. Власникам  ID-картки необхідно ввести тільки 9 цифр та нічого не вказувати в графі “Серія паспорта”. </li>
        </ul></p>
        Якщо форму заповнює уповноважена особа від імені людини з інвалідністю, необхідно додатково вказати: 
        <p><ul className={styles.discriptionList}>
          <li>РНОКПП уповноваженої особи</li>
          <li>Серію та номер свідоцтва про народження особи з інвалідністю.</li>
        </ul></p>
        <strong>Прикріпіть скановані копії або фото документів:</strong><br />
        <p><ul className={styles.discriptionList}>
          <li>паспорт</li>
          <li>податковий номер</li>
          <li>документ, що дає право на пільгу (пенсійне посвідчення, висновок МСЕК або ЛКК, ВЛК)</li>
        </ul></p>
        <p><strong>Зверніть увагу:</strong>сканкопії документів не повинні перевищувати 3 МБ.</p>
        <p><strong>Дайте згоду на обробку персональних даних.</strong></p>
        Натисніть кнопку <strong>«Зареєструватись» і введіть дані КЕП:</strong><br />
        <p><ul className={styles.discriptionList}>
          <li>оберіть зі списку організацію, яка надала електронний підпис;</li>
          <li>оберіть файл підпису з комп’ютера або флешки;</li>
          <li>введіть пароль.</li>
        </ul></p>
        <p>Для того, щоб зберегти заяву в ЦБІ, необхідно натиснути кнопку <strong>«Продовжити».</strong></p>
        <p>Якщо дані успішно збережені, ви побачите повідомлення <strong>«Увага! Ваша заява на реєстрацію відправлена в ЦБІ».</strong></p>
          </p>*/}
      
      <p id="reg">
        <strong>Реєстрація в ЦБІ</strong><br />
        <br/>
        У цьому розділі ви можете здійснити реєстрацію в ЦБІ. Для цього потрібно:
        <p><ol className={styles.discriptionList}>
        &emsp;&bull;&emsp;зайдіть до Особистого кабінету. <strong>Вхід в кабінет відбувається:</strong><br />
        &emsp;&emsp;&emsp;за КЕП (Що таке КЕП? Як отримати КЕП?);<br />
        &emsp;&emsp;&emsp;за ID.GOV.UA (це державна платформа для електронної ідентифікації	користувачів для отримання ними електронних послуг).<br />
          	&emsp;&bull;&emsp;заповніть реєстраційну форму;<br />
          	&emsp;&bull;&emsp;прикріпіть сканкопії або фото запитуваних документів;<br />
          	&emsp;&bull;&emsp;введіть дані КЕП;<br />
          	&emsp;&bull;&emsp;дайте згоду на обробку персональних даних.<br />
        </ol></p>
        <p>
        Якщо дані успішно збережені, ви побачите повідомлення <strong>«Увага! Ваша заява на реєстрацію в ЦБІ відправлена».</strong>
        Зареєструватися на порталі можна з електронним підписом (КЕП) або без нього. Якщо у вас немає КЕП і ви не хочете його отримувати, зверніться до органу соцзахисту, де вам видадуть спеціальний <strong>код авторизації</strong> на порталі.
        </p>
        <p><ol className={styles.discriptionList}>
        <strong>Скановані копії або фото документів, які необхідно прикріпити:</strong><br />
          	&emsp;&bull;&emsp;паспорт або інший документ, що посвідчує особу (свідоцтво про народження для дитини)<br />
          	&emsp;&bull;&emsp;податковий номер (РНОКПП)<br />
          	&emsp;&bull;&emsp;документ, що дає право на пільгу (пенсійне посвідчення, висновок МСЕК або ЛКК, ВЛК, МДРК)<br />
          	&emsp;&bull;&emsp;документ, який посвідчує право на послугу та її вид (пенсійне посвідчення, висновок МСЕК або ЛКК, ВЛК, МДРК)<br />
          	&emsp;&bull;&emsp;додаткові документи (у разі необхідності. Наприклад, підтвердження повноважень представника особи)<br />
        </ol></p>
      </p>
      <NavLink className={styles.button} to='/login'>Вхід в особистий кабінет</NavLink>
      <br/>
      <p id="how">
        <p>
        <strong>Як подати заяву, якщо ви законний представник людини з інвалідністю</strong><br />
        </p>
        <p><ol className={styles.discriptionList}>
        Якщо реєстраційну форму заповнює <strong>законний представник</strong> від імені особи з інвалідністю або дитини з інвалідністю, необхідно додатково вказати:<br />
          	&emsp;&bull;&emsp;РНОКПП законного представника<br />
          	&emsp;&bull;&emsp;Серію паспорта для особи з інвалідністю або свідоцтва про народження для дитини з інвалідністю<br />
          	&emsp;&bull;&emsp;Номер паспорта для особи з інвалідністю або свідоцтва про народження для дитини з інвалідністю.<br />
        </ol></p>

        <p>
          Якщо ви подаєте заяву як <strong>законний представник людини з інвалідністю</strong>, ви заповнюєте дані та подаєте документи особи з інвалідністю, але використовуєте <strong>свій власний КЕП.</strong>Законному представнику необхідно мати електронну копію документу,
що підтверджує його повноваження.
        </p>
        <p>
          Подаєте заяву від імені дитини з інвалідністю? Використовуйте свій РНОКПП.
        </p>
        <div className={styles.video} >
        <img src={play} alt='play'></img>
      </div>

      </p>


    </div>
  )
}
