import React from 'react'
import Select from '../../Select/Select';
import styles from './index.module.css';

const options1 = [
  'Menu Item',
  'Menu Item',
  'Menu Item',
]

export default function ServicesDRZ() {
  return (
    <div className={styles.container}>
      <span className={styles.sub} >Зміст сторінки:</span><br/>
      <br/>
      <a className={styles.anchor} href='#info'>Суб’єкти господарювання, які виготовляють, постачають і ремонтують ДЗР</a>
      <a className={styles.anchor} href='#service'>Реабілітаційні заклади</a>
      <p id="info"><strong>Суб’єкти господарювання, які виготовляють, постачають і ремонтують допоміжні засоби реабілітації</strong> — це підприємства, які спеціалізуються на виготовленні та постачанні різноманітних засобів реабілітації (протезів, ортезів, крісел колісних, ортопедичного
взуття, засобів для орієнтування та спілкування тощо).</p>
      <p id="service"><strong>Реабілітаційні заклади</strong> — установа, підприємство, організація, у тому числі їх відділення, структурні підрозділи, незалежно від форми власності, що здійснює реабілітацію осіб з інвалідністю і дітей з інвалідністю відповідно до державних
соціальних нормативів у сфері реабілітації </p>
      {/*<p>Користуючись фільтрами, шукайте <strong>підприємства протезної галузі</strong> та <strong>реабілітаційні центри</strong> у своєму регіоні або відповідно до ваших потреб.</p>
      <p>Для цього оберіть область, район та назву.</p>
      <br />
      <div className={styles.filters}>
          <div className={styles.filter}><Select options={options1} selectName={'Область'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Район'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Назва'}/></div>
      </div>
      <div className={styles.results}>
    <div className={styles.result}>1) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>2) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>3) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>4) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>5) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>6) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
    <div className={styles.result}>7) 21029 ВІННИЦЬКА ОБЛАСТЬ , ВІННИЦЯ , ВІННИЦЬКИЙ РАЙОН ВУЛ. ХМЕЛЬНИЦЬКЕ ШОССЕ 126</div>
      </div>*/}
    </div>
  )
}
