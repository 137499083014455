import React from 'react'
import useWindowDimensions from '../../../hooks/getWindowDimensions/getWindowDimensions'
import styles from './index.module.css';
import Select from '../../Select/Select';

const options1 = [
  'Menu Item',
  'Menu Item',
  'Menu Item',
]

export default function Enterprise() {
  const { width } = useWindowDimensions();
  const isTablet = width < 1300;
  return (
    <div>
      <h3 className={styles.title}>Заявки про намір забезпечення засобами реабілітації (вибір суб’єктів господарювання,  які виготовляють, постачають і ремонтують ДЗР)</h3>
      <div className={styles.discription}>У цьому розділі ви можете подати до обраного суб’єкта господарювання, який
      виготовляє, постачає і ремонтує допоміжні засоби реабілітації, заявку про намір
      забезпечення тим чи іншим засобом реабілітації.<br/>
      <a className={styles.link}><u>Підприємства, які виготовляють, постачають і ремонтують допоміжні засоби реабілітації</u></a><br/>
      Для подання нової заявки про намір забезпечення, <strong>заповніть форму</strong>:
      Після цього натисніть кнопку <strong>«Відправити заявку».</strong><br/>
      Тут також міститься інформація про підприємства, до яких ви подавали заявки раніше.<br />
        </div>
      <br />
      <p><u><strong>Подати нову заяву</strong></u></p>
      <div>
        <div className={styles.formTitle}>Для подачі нової заяви подайте форму</div>
        <div className={styles.formArea}>
          <div className={styles.selectBlock}>
            <select className={styles.select}>
            <option value="">Підприємство</option>
            </select>
            <select className={styles.select}>
            <option value="">Направлення</option>
            </select>
          </div>
          <div className={styles.loadBlock}>
            <div className={styles.loadBlockTop}>
              <div className={styles.loadBlockTitle}>Скан-копії документів</div>
              <div className={styles.loadBlockButton}>Додати файл</div>
            </div>
            <div className={styles.loadBlockBottom}>Скан-копії документів (розмір файлів не повинен перевищувати 3 Мб)</div>
          </div>
        </div>
        <div className={styles.blankButton}>Відправити Заявку</div>
        {/*<div className={styles.filters}>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
          <div className={styles.filter}><Select options={options1} selectName={'Фільтр'}/></div>
      </div>
      <div className={styles.results}>
      {!isTablet ? 
      <div className={styles.results}>
      <div className={styles.result}>
          <div>1. 16.02.2024</div>
          <div>16.02.2024</div>
          <div>Назва виробника</div>
          <div>На розгляді</div>
          <div>Назва регіону</div>
        </div>
    </div> 
    :
    <div><p><strong>Вивід результатів у розробці....</strong></p></div>
    }
        
  </div>*/}
      </div>

    </div>
  )
}
