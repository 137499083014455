import React, { useMemo, useState } from 'react'
import styles from './index.module.css';
import IdGov from '../../components/Login/IdGov/IdGov';
import KEP from '../../components/Login/KEP/KEP';

export default function Login() {
  const [loginArea, setLoginArea] = useState('idGov')
  const [title, setTitle] = useState('Надавачі послуг')
	const [showSubMenuFirst, setShowSubMenuFirst] = useState(false)

  
  const shownArea = useMemo(() => {
    switch(loginArea) {
      case 'idGov': setTitle('Увійти до системи за ID.GOV.UA'); return <IdGov />
      case 'kep': setTitle('Увійти до системи за КЕП');  return <KEP />
      default:
       <IdGov />
    }
  }, [loginArea])
	
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
    <p> Content toward the top of the page should talk about the most important information you want to share. It's a good place to give an overview or introduction to your company or product and should be relevant to the rest of the content on the page.</p>
    <br />
    <div className={styles.loginFormArea}>
      <div className={styles.tabButtons}>
      <div className={`${styles.tabButton} ${loginArea === 'idGov' ? styles.active : ''} ${showSubMenuFirst ? styles.tabButtonShow : null}`} onClick={() => { setLoginArea('idGov') }}>За ID.GOV.UA</div>
      <div className={`${styles.tabButton} ${loginArea === 'kep' ? styles.active : ''} ${showSubMenuFirst ? styles.tabButtonShow : null}`} onClick={() => {setLoginArea('kep')}}>За КЕП</div>
      </div>


    <div className={styles.rightSide}>
      {shownArea}
    </div>
    </div>
  </div>
  )
}

