import React from 'react'
import styles from './index.module.css';

export default function DefaultBlock() {
  return (
    <>
      <p className={styles.title}>Вітаємо в електронному кабінеті особи з інвалідністю , де ви можете отримати послуги онлайн, не виходячи з дому!</p>
      <a className={styles.anchor} href='#why_to_reg'>Подати заяву</a>
      <a className={styles.anchor} href='#how'>Як управляти заявами</a>
      <div className={styles.subTitle}>Обирайте, що потрібно саме вам, за допомогою кнопок нижче.</div>
      <div className={styles.rightSideButtons}>
        <div className={styles.rightSideButton}>Потрібні ДЗР або грошова<br /> компенсація. Подати заяву</div>
        <div className={styles.rightSideButton}>Потрібна реабілітація.<br /> Подати заяву</div>
        <div className={styles.rightSideButton}>Потрібен ремонт ДЗР.<br /> Подати заявку</div>
        <div className={styles.rightSideButton}>Хочу обрати суб&#39;єкта <br />господарювання. Подати заяву</div>
        <div className={styles.rightSideButton}>Потрібно стати на облік на <br />автомобіль. Подати заяву</div>
        <div className={styles.rightSideButton}>Потрібно подати заяву на виплату грошової компенсації за самостійно придбаний ДЗР. Подати заяву</div>
      </div>
      <div className={styles.bottomSide}>
      <p id='how'><strong>Як управляти заявами</strong></p>
      <p>Відстежуйте історію своїх заяв, дізнавайтеся результат їх розгляду та читайте інші
        інформаційні повідомлення у <strong>лівому бічному меню.</strong></p>
      
        <strong>Вхідні повідомлення — </strong>тут ви можете перевірити отримані повідомлення. <br/>
        <strong>Заяви про потребу у забезпеченні ДЗР — </strong>тут ви знайдете перелік поданих вамизаяв.<br/>
<strong>Отримані ДЗР – </strong>тут вказані отримані вами допоміжні засоби реабілітації.<br/>
<strong>Заяви про направлення до реабілітаційних установ – </strong>тут ви зможете побачити свої
заяви на потребу в отриманні реабілітаційних послуг.<br/>
<strong>Заявки про намір забезпечення ДЗР</strong> (вибір суб’єктів господарювання – тут ви
побачите обраних виробників ДЗР).<br/>
<strong>Відмови в отриманні ДЗР - </strong>тут ви знайдете свої заяви у разі відмови в отриманні
наданні ДЗР.<br/>
<strong>Заявки на проведення ремонту – </strong>тут знаходяться ваші заявки, якщо ДЗР зламався і
потребує ремонту або технічного обслуговування<br/>
      <strong>Оновлення документів — </strong>у цьому розділі ви можете додати нові документи.
      </div>
    </>
  )
}

