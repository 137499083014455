import React, { useState } from 'react'
import styles from './index.module.css';

export default function Select({selectName}) {
const [show, setSwow] = useState(false)
  
  return (
    <div className={styles.wrapper}>
    <div className={styles.dropdown}>
      <div
      className={`${styles.button} ${!show ? styles.show : ''}`}
      onClick={() => setSwow(!show)}
      >
        {selectName}
      </div>
      <ul className={`${styles.dropList} ${show ? styles.show : ''}`}>
        <li className={styles.listItem}>MenuItem</li>
        <li className={styles.listItem}>MenuItem</li>
        <li className={styles.listItem}>MenuItem</li>
        <li className={styles.listItem}>MenuItem</li>
      </ul>
    </div>
   </div>
  )
}

