import React, {useState} from 'react'
import {useLocation, NavLink} from 'react-router-dom'
import SubHeader from '../SubHeader/SubHeader'
import styles from './index.module.css';
import logo from '../../img/logo.svg'
import loginIco from '../../img/login.png'
import loginIcoActive from '../../img/logo-active.png'

export default function Header() {
  const localtion = useLocation();
  const isMain = localtion.pathname === '/';
  const [hover, setHover] = useState(false);


  
  return (
    <header className={styles.container}>
      <div className={styles.wrapper}>
      <div className={styles.logo}>
        <img src={logo} alt='logo'/>
      {!isMain ? <div className={styles.title}>Електронний кабінет особи з інвалідністю</div> : null }
      </div>
      <NavLink className={styles.login} to='/login'>
        <div className={styles.loginTitle}>{false ? 'ВИХІД' : 'ВХІД'}</div>
        <img className={styles.loginIco} src={hover ? loginIcoActive : loginIco} alt='login' onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}></img>
      </NavLink>
      </div>
      <SubHeader isMain={isMain} />
    </header>
  )
}
